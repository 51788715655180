<div class="dialogContainer">
  <div mat-dialog-title>
    <span (click)="dialogRef.close()">
      <mat-icon class="close" svgIcon="close_icon"></mat-icon>
    </span>
  </div>
  
  <div mat-dialog-content>
    <ng-container *ngIf="icon !== ''">
      <div class="mat-dialog-content_logo">
        <mat-icon [svgIcon]="icon"></mat-icon>
      </div>
    </ng-container>
    <ng-container *ngIf="data.descriptions !== ''">
      <h1 class="mat-dialog-content_title" *ngIf="data.title !== ''">
        {{ data.title }}
      </h1>
      <div class="mat-dialog-content_description" *ngIf="!data?.genCannotV3">
        <span [outerHTML]="data.descriptions"></span>
      </div>
      <div class="mat-dialog-content_description" *ngIf="data?.genCannotV3">
        <span style="color: rgb(40, 199, 111);">{{data.successCount}} {{languageData.successful}}
        </span>
        <p>{{languageData.note}}</p>
        <p></p>
        <span style="color: red">{{data.failedCount}} {{languageData.failed}}
        </span>
        <p>{{languageData.request_pickup}}</p>
      </div>
      <div class="mat-dialog-content_information" *ngIf="data.information !== ''">
        <span [innerHTML]="data.information"></span>
      </div>
    </ng-container>
  </div>
  
  <div mat-dialog-actions>
    <ng-container *ngIf="data.confirmEvent">
      <div class="mat-dialog-actions_confirm" [class.stretch]="data.information">
        <button *ngIf="!data.actionUrl" mat-raised-button mat-button color="primary" (click)="confirmEvent.emit(true)">{{data.actionText}}</button>
        <button *ngIf="data.actionUrl" mat-raised-button mat-button color="primary" (click)="actionBtn(data.actionUrl)">{{data.actionText}}</button>
      </div>
    </ng-container>
  </div>
</div>
