import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EzisendIconModule } from '@pos/ezisend/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { HttpClientModule } from '@angular/common/http';
import { GeneralFormModule } from '@pos/ezisend/shared/ui/forms/general-form';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    EzisendIconModule,
    GeneralFormModule,
  ],
  declarations: [DialogComponent],
  exports: [DialogComponent],
})
export class EzisendSharedUiDialogModule {}
