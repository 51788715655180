import { Component, ChangeDetectionStrategy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { LoginFacade } from '@pos/ezisend/auth/data-access/store';
import { CommonService } from '@pos/ezisend/shared/data-access/services';
import { BreakpointService } from '@pos/ezisend/shell/data-access/models';

@Component({
  selector: 'pos-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  sidebarActive = false;
  widthScreen = window.innerWidth;

  @ViewChild('navSidebar') navSidebarELement!: ElementRef;
  @HostListener("window:resize", []) updateMode() {
    this.listenWidthScreen();
  }

  constructor(
    public breakpointService: BreakpointService,
    public commonService: CommonService,
    private authStore: LoginFacade
  ) {
    this.authStore.init();
    this.listenWidthScreen();
  }

  navItemClick() {
    if (this.widthScreen <= 768) this.sidebarActive = false;
  }

  private listenWidthScreen() {
    this.widthScreen = window.innerWidth;
  }
}
