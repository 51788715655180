import { Injectable } from '@angular/core';

declare var clevertap: any;

@Injectable({
  providedIn: 'root'
})
export class CleverTapService {
  platform = "SendParcel Pro web"
  constructor() { }

  trackEvent(data: any, eventName: string) {
    clevertap.event.push(eventName, {
      "platform": this.platform
    })
    clevertap.onUserLogin.push({
        "Site": {
           "Identity": data.account_no      
        }
        })
  }

  SPPtrackEvent(data: any, eventName: string, isOrderPlatform?: boolean) {
    data = {
      ...data,
      [isOrderPlatform ? 'Order platform' : 'Platform']: this.platform,
    }
    clevertap.event.push(eventName, data)
  }
}