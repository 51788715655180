/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { DataPersistence, fetch } from '@nrwl/angular';
//TODO: Fix circular dependency
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { LoginService } from '@pos/ezisend/auth/data-access/services';
import { map } from 'rxjs/operators';
import { AuthErrorCodes } from '@pos/ezisend/auth/data-access/models';
import { CleverTapService } from '@pos/ezisend/shared/data-access/services';

import * as LoginActions from './login.actions';
import * as LoginFeature from './login.reducer';

@Injectable()
export class LoginEffects {
  eventName:any;
  init$ = createEffect(() => {
    return this.dataPersistence.fetch(LoginActions.init, {
      run: (
        action: ReturnType<typeof LoginActions.init>,
        state: LoginFeature.LoginPartialState
      ) => {
        const token = localStorage.getItem('authToken');
        if (token) {
          return LoginActions.loginSuccess({
            login: {
              code: '',
              message: 'Token retreived from local storage',
              data: { token },
            },
            redirect: false,
          });
        } else {
          throw new Error('No auth token found');
        }
      },
      onError: (action: ReturnType<typeof LoginActions.init>, error) => {
        return LoginActions.loginFailure({ error: error.message });
      },
    });
  });

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoginActions.login),
      fetch({
        run: (action) => {
          return this.service.login(action.login).pipe(
            map((res) => {
              if (res.data?.token) {
                const userData = JSON.parse(atob(res.data.token.split('.')[1]));
                this.eventName = "Login"
                this.clevertapService.trackEvent(userData,this.eventName)
                localStorage.setItem('authToken', res.data.token);
                return LoginActions.loginSuccess({ login: res, redirect: true });
              } else {
                return LoginActions.loginFailure({ error: res.message });
              }
            })
          );
        },
        onError: (action, error: HttpErrorResponse) => {
          const errorMessage = AuthErrorCodes[error.error.error.code as keyof typeof AuthErrorCodes] ?? 'unauthorized';
          return LoginActions.loginFailure({ error: errorMessage });
        },
      })
    );
  });

  loginFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.loginFailure),
        map((action) => {
          localStorage.removeItem('authToken');
        })
      );
    },
    { dispatch: false }
  );

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.logout),
        map((action) => {
          const token:any = localStorage.getItem('authToken');
          this.eventName = "Logout"
          const userData = JSON.parse(atob(token.split('.')[1]));
          this.clevertapService.trackEvent(userData,this.eventName)

          localStorage.removeItem('authToken');
          this.router.navigate(['/auth/login']);
        })
      );
    },
    { dispatch: false }
  );

  loginSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LoginActions.loginSuccess),
        map((action) => {
          if (action.redirect) {
            return this.router.navigateByUrl('/')
          }

          let decodedPathHistory = null;
          const pathHistory = localStorage.getItem('pathHistoryData');
          if (pathHistory) {
            decodedPathHistory = JSON.parse(pathHistory);
            localStorage.removeItem('pathHistoryData');
          }

          if (!decodedPathHistory) {
            return false;
          }

          let absolutePath = decodedPathHistory.base;
          absolutePath += decodedPathHistory.queryParam ? `?${decodedPathHistory.queryParam}` : '';

          return this.router.navigateByUrl(absolutePath);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly dataPersistence: DataPersistence<LoginFeature.LoginPartialState>,
    private service: LoginService,
    private router: Router,
    public clevertapService: CleverTapService
  ) {}
}
