import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@pos/shared/environments';

@Injectable()
export class EzisendInterceptor implements HttpInterceptor {

  constructor(private router: Router, private _commonService: CommonService) {
    
  }

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this._commonService.downTimeChkOut(this._commonService.mDate, this._commonService.fromDate, this._commonService.toDate);
    /**
     * We are skipping the interceptor for any request that have an authorization header set.
     * This is because we have few services such as in CommonService that require the authorization header to be set as Basic Auth.
     */

    if (httpRequest.headers.has('Authorization')) {
      return next.handle(httpRequest);
    }

    /**
     * We are retreiving the authorization header from the local storage. If there's no authorization header in the local storage,
     * we are returning the request as it is.
     */
    const authToken = localStorage.getItem('authToken');
    const pReqId = localStorage.getItem('pRequestId');

    if (authToken) {
      httpRequest = httpRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
          P_Request_Id: `${pReqId}`,
        },
      });
    }
    return next.handle(httpRequest).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this._commonService.logout();
          }
          if ((err.error?.status === 403 && err.error?.error?.code === 'E1004')) {
            this._commonService.openCustomErrorDialog(err);
          }
          return throwError(() => ({error: err}));
        }
        return throwError(() => ({error: err}));
      })
    );
  }
}
